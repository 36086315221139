<template>
    <div class="note-container container">
        <div v-if="customerAgree" class="resolve-content">
            <div class="item-title">您共选择了 {{ images.length }} 款设计</div>
            <img v-for="image in images" :src="$fillOssPath(image)" :key="image" />
        </div>
        <div v-else class="reason-content">
            <div class="item-title">不满意原因</div>
            <div class="reason-box">{{ reason | rejectReason }}</div>
        </div>
        <div class="remark-content">
            <div class="item-title">您的修改建议</div>
            <div class="remark-box">{{ customerRemark }}</div>
        </div>
    </div>
</template>

<script>
import { getProposalNote } from '@/api/task';
import { REJECT_REASON } from '@/constant/task';

export default {
    name: 'task-proposal-note',
    mounted() {
        this.init();
    },
    computed: {
        // 显示被选中的图
        images() {
            const images = [];
            this.proposalData?.item?.forEach((item) => {
                item.images.forEach((image) => {
                    if (image.status == 1) {
                        images.push(image.image);
                    }
                });
            });
            return images;
        },
        // 客户意见
        customerRemark() {
            return this.proposalData.item && this.proposalData.item[0].customer_remark;
        },
        // 客户是否满意 满意返回 true
        customerAgree() {
            return this.proposalData.item && this.proposalData.item[0].reason === 0;
        },
        reason() {
            return this.proposalData.item && this.proposalData.item[0].reason;
        },
    },
    data() {
        return {
            proposalData: {},
        };
    },
    methods: {
        init() {
            const { taskNo, code } = this.$route.params;
            getProposalNote(taskNo, code).then(([response, error]) => {
                if (!error) {
                    this.proposalData = response.data;
                }
            });
        },
    },
    filters: {
        rejectReason(value) {
            return REJECT_REASON[value];
        },
    },
};
</script>

<style lang="scss" scoped>
.note-container {
    .item-title {
        color: #666;
        margin-bottom: 0.5rem;
    }
    .resolve-content {
        img {
            display: block;
            width: 100%;
            margin-bottom: 0.4rem;
        }
    }
    .reason-content {
        .reason-box {
            color: #333;
        }
    }
    .remark-content {
        margin-top: 1rem;
        .remark-box {
            background-color: #ededed;
            border-radius: 0.5rem;
            min-height: 4rem;
            padding: 0.5rem;
            color: #333;
        }
    }
}
</style>
