// 不满意原因
export const REJECT_REASON = {
    1: '需求理解有误',
    2: '提案文件不全',
    3: '提案表达不明确',
    4: '设计水平不满意',
    5: '其他原因',
};

export const REJECT_REASON_MAP = {
    需求理解有误: 1,
    提案文件不全: 2,
    提案表达不明确: 3,
    设计水平不满意: 4,
    其他原因: 5,
};
